
      
      
    

      
      
    

      
      
    
[data-page-element="CourseSidebar/V3"] {
      width: 325px;
max-width: 100%;
min-height: 100vh;
margin: 0px;
    }
      [data-page-element="CourseSidebar/V3"][data-render-mode="ascension"][data-render-mode="ascension"] .elCourseSidebar  {
      gap: 0;
    }
[data-page-element="CourseSidebar/V3"] .elCourseSidebar {
      width: inherit;
max-width: inherit;
min-height: 100vh;
padding-top: 15px;
margin: 0px;
display: flex;
gap: 30px;
flex-direction: column;
--left-indentation: 15;
    }
[data-page-element="CourseSidebar/V3"] [data-sidebar-depth="0"] {
      padding-left: 15px;
    }
[data-page-element="CourseSidebar/V3"] .elCourseSidebarItemWithinModule {
      padding-left: calc(var(--depth) * var(--left-indentation) * 1px);
    }
[data-page-element="CourseSidebar/V3"] .elCourseSidebarItem {
      display: flex;
position: relative;
margin: 0px;
align-items: center;
padding-top: 15px;
padding-bottom: 15px;
    }
[data-page-element="CourseSidebar/V3"] .elCourseSidebarItem a,
[data-page-element="CourseSidebar/V3"] .elCourseSidebarTabsItem a {
      line-height: normal;
    }
[data-page-element="CourseSidebar/V3"] [data-page-element="CourseSidebarItem/V2"] {
      width: 100%;
margin: 0px;
    }
[data-page-element="CourseSidebar/V3"] [data-page-element="CourseSidebarLinks/V1"] {
      width: 100%;
display: flex;
flex-direction: column;
padding-bottom: 15px;
padding-left: 20px;
padding-right: 20px;
    }
[data-page-element="CourseSidebar/V3"] [data-page-element="CourseSidebarLinks/V1"] .elTypographyLink {
      padding-top: 10px;
padding-bottom: 10px;
    }
[data-page-element="CourseSidebar/V3"] [data-page-element="CourseSidebarLinks/V1"] .elTypographyLink i {
      color: inherit;
margin-right: 10px;
font-size: calc(100% + 3px);
    }
[data-page-element="CourseSidebar/V3"] [data-page-element="CourseSidebarTabs/V1"] {
      width: 100%;
display: flex;
position: relative;
    }
[data-page-element="CourseSidebar/V3"] [data-page-element="CourseSidebarTabs/V1"] .elCourseSidebarTabsScroller {
      display: flex;
overflow-x: scroll;
flex-shrink: 0;
width: 100%;
-ms-overflow-style: none;
position: relative;
scrollbar-width: none;
scroll-behavior: smooth !important;
    }
[data-page-element="CourseSidebar/V3"] [data-page-element="CourseSidebarTabs/V1"] .elCourseSidebarTabsScroller::-webkit-scrollbar {
      display: none;
    }
[data-page-element="CourseSidebar/V3"] [data-page-element="CourseSidebarTabs/V1"] .elCourseSidebarTabsItem {
      flex-grow: 1;
flex-shrink: 0;
padding-left: 10px;
padding-right: 10px;
min-width: 50px;
display: flex;
padding-top: 10px;
padding-bottom: 10px;
    }
[data-page-element="CourseSidebar/V3"] [data-page-element="CourseSidebarTabs/V1"] .elCourseSidebarTabsItem a {
      text-align: center;
width: 100%;
    }
[data-page-element="CourseSidebar/V3"] [data-page-element="CourseSidebarTabs/V1"]:hover > .elCourseSidebarTabsScrollButton {
      opacity: 1;
    }
[data-page-element="CourseSidebar/V3"] [data-page-element="CourseSidebarTabs/V1"] .elCourseSidebarTabsScrollButton {
      position: absolute;
height: 100%;
background: rgb(250, 250, 250, 45%);
width: 20px;
display: none;
align-items: center;
cursor: pointer;
transition: all .2s ease;
z-index: 1;
padding: 0px;
border: 0;
opacity: 0;
    }
[data-page-element="CourseSidebar/V3"] [data-page-element="CourseSidebarTabs/V1"] .elCourseSidebarTabsScrollButton:hover {
      background: rgb(250, 250, 250, 85%);
    }
[data-page-element="CourseSidebar/V3"] [data-page-element="CourseSidebarTabs/V1"] .elCourseSidebarTabsScrollButtonRight {
      right: 0px;
    }
[data-page-element="CourseSidebar/V3"] .elCourseSidebarItemSection[data-expanded="false"] {
      display: none;
    }
[data-page-element="CourseSidebar/V3"] a {
      color: inherit;
text-decoration: none;
white-space: nowrap;
text-overflow: ellipsis;
margin: 0px;
overflow: hidden;
    }
[data-page-element="CourseSidebar/V3"] .sidebarItemIcon {
      color: inherit;
cursor: pointer;
margin: 0px;
right: 0px;
margin-right: 20px;
    }
[data-page-element="CourseSidebar/V3"] .sidebarItemCompletedIcon {
      color: inherit;
cursor: pointer;
margin-right: 10px;
    }
[data-page-element="CourseSidebar/V3"] .elCourseSidebarHamburgerOverlay {
      position: fixed;
top: 0px;
left: 0px;
width: 100%;
height: 100%;
opacity: 0;
visibility: hidden;
transition: opacity .35s, visibility .35s, height .35s;
overflow: hidden;
background: black;
z-index: -1;
    }
[data-page-element="CourseSidebar/V3"] .elCourseSidebarHamburgerInput {
      display: none;
    }
      @media (max-width: 770px) {
        [data-page-element="CourseSidebar/V3"] {
      min-height: 0px;
    }
      [data-page-element="CourseSidebar/V3"] .elCourseSidebar {
      visibility: hidden;
position: fixed;
top: 0px;
left: -770px;
height: 100%;
z-index: 200;
transition: left 0.5s;
box-sizing: border-box;
overflow-y: auto;
    }
[data-page-element="CourseSidebar/V3"] .elCourseSidebarOpen {
      visibility: visible;
left: 0px;
    }
[data-page-element="CourseSidebar/V3"] .elCourseSidebarHamburgerInput:checked ~ .elCourseSidebarHamburgerOverlay {
      visibility: visible;
opacity: 0.4;
    }
[data-page-element="CourseSidebar/V3"] .elCourseSidebarHamburgerBurgwrap {
      z-index: 100;
width: 20px;
height: 20px;
background: #FFF;
border-radius: 50% 50% 50% 50%;
transition: transform .3s ease-in-out;
box-shadow: 0 0 0 0 #FFF, 0 0 0 0 #FFF;
cursor: pointer;
    }
[data-page-element="CourseSidebar/V3"] .elCourseSidebarHamburgerHangaburg {
      position: relative;
width: 20px;
height: 2px;
display: block;
transform-origin: center;
transition: transform .5s ease-in-out;
    }
[data-page-element="CourseSidebar/V3"] .elCourseSidebarHamburgerHangaburg:after,
[data-page-element="CourseSidebar/V3"] .elCourseSidebarHamburgerHangaburg:before {
transition: transform .3s ease-in-out;
content: "";
position: absolute;
display: block;
width: 100%;
height: 100%;
background: inherit;
    }
[data-page-element="CourseSidebar/V3"] .elCourseSidebarHamburgerHangaburg:before {
      top: -7px;
    }
[data-page-element="CourseSidebar/V3"] .elCourseSidebarHamburgerHangaburg:after {
      bottom: -7px;
    }
[data-page-element="CourseSidebar/V3"] .elCourseSidebarHamburgerInput:checked ~ .elCourseSidebarHamburgerBurgwrap .elCourseSidebarHamburgerHangaburg {
transform: rotate(45deg);
    }
[data-page-element="CourseSidebar/V3"] .elCourseSidebarHamburgerInput:checked ~ .elCourseSidebarHamburgerBurgwrap .elCourseSidebarHamburgerHangaburg:after {
transform: rotate(90deg);
bottom: 0px;
    }
[data-page-element="CourseSidebar/V3"] .elCourseSidebarHamburgerInput:checked ~ .elCourseSidebarHamburgerBurgwrap .elCourseSidebarHamburgerHangaburg:before {
transform: rotate(90deg);
top: 0px;
    }
[data-page-element="CourseSidebar/V3"] [data-page-element="CourseSidebarHamburgerMenu/V1"] {
      position: fixed;
top: 3em;
right: 20px;
width: 20px;
height: 20px;
border: none;
padding: 0px;
margin: 0px;
z-index: 200;
display: inline;
    }
      
    
      }
    